
import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import InfoDialogLayout from '@/layouts/InfoDialogLayout.vue'
import auth from '@/store/modules/auth'
import split from '@/store/modules/split'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'
import { SplitFeatureFlag } from '@/utils/enum'

@Component({ components: { InfoDialogLayout } })
export default class PromptCallDialog extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean

  split = split
  useNewLastMinuteTrip = false

  @Watch('split.isReady', { immediate: true })
  async onSplitReady(isReady: boolean): Promise<void> {
    if (!isReady) {
      return
    }
    const res = await split.getTreatmentConfig(
      SplitFeatureFlag.SalesbotLastMinuteQuote
    )
    this.useNewLastMinuteTrip = res.treatment === 'on'
  }

  quotePromptCallNumber(): void {
    window.open(`tel:${this.formattedSupportNumber}`)
  }
  get supportNumber(): string {
    return getSupportNumber(auth.customer.isElite)
  }
  get formattedSupportNumber(): string {
    return `1-${phoneFormatFilterHyphens(this.supportNumber)}`
  }
  get dialogBody(): string {
    return this.useNewLastMinuteTrip
      ? 'Your requested pickup time is less than 2 hours away or outside our working hours. Please call us now to receive a quote.'
      : 'Your requested pickup time is less than 24 hours away. Please call us now to receive a quote.'
  }
}
