
import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import ShareQuoteDialog from '@/components/ShareQuoteDialog.vue'
import { CustomerAccount, Quote } from '../models/dto'
import { createDownloadUri, triggerFileDownload } from '@/utils/download'
import { PricingMethod } from '@/utils/enum'
import auth from '../store/modules/auth'
import alert from '@/store/modules/alert'
import support from '../store/modules/support'
import quotes from '@/services/quotes'
import CustomerAccountDefaultValueSet from '@/models/dto/CustomerAccountDefaultValueSet'

@Component({ components: { ShareQuoteDialog } })
export default class CheckoutHeader extends Vue {
  @Inject({ from: 'isInGuestCheckout', default: false }) readonly isInGuestCheckout: boolean

  @Prop({ type: Object, required: true }) quote!: Quote
  @Prop({ type: Boolean, default: false }) isSingleColumn!: boolean
  @Prop({ type: Boolean, default: false }) fullWidth!: boolean
  @Prop({ type: Object, default: () => {} }) customerAccount!: CustomerAccount
  @Prop({ type: Object, default: () => {} }) effectiveDefaultValueSet!: CustomerAccountDefaultValueSet

  loading = false

  get quoteId(): number {
    return this.quote?.quoteId
  }

  get isBidListQuote(): boolean {
    return this.quote?.pricingMethod === PricingMethod.Bids
  }

  get quoteCustomerAccountMatchesLoggedInCustomerAccount() {
    return (
      this.quote?.trips[0]?.customer?.customerAccountId &&
      this.quote?.trips[0]?.customer.customerAccountId ===
        this.customerAccount?.customerAccountId
    )
  }

  get headerText(): string {
    const { name } = this.customerAccount || {}
    if (
      this.quoteCustomerAccountMatchesLoggedInCustomerAccount &&
      this.customerAccount.nameApprovedForUserInterface &&
      name
    ) {
      return `Your ${name} trip is ready to book!`
    }
    return 'Your trip is ready to book!'
  }

  get showShareQuoteDialog(): boolean {
    if (!this.quote) {
      return false
    }
    return this.isUserQuoteCustomer || this.isUserInSameOrganization
  }

  get isUserQuoteCustomer(): boolean {
    const quoteCustomerId = this.quote?.trips?.[0]?.customerId
    return quoteCustomerId === auth.user?.userId
  }

  get isUserInSameOrganization(): boolean {
    const quoteCustomerCustomerAccountId = this.quote?.trips?.[0]?.customer?.customerAccountId
    if (!quoteCustomerCustomerAccountId) {
      return false
    }
    return (
      quoteCustomerCustomerAccountId ===
        auth.customerAccount.customerAccountId ||
        auth.childCustomerAccountIds.includes(quoteCustomerCustomerAccountId)
    )
  }

  async downloadQuoteInvoice(): Promise<void> {
    /*
      Since the PDF service does not currently have an endpoint to return quote invoices
      with content-type 'application/pdf' (currently only supports JSON), we cannot simply
      include an a-tag with the URL as it's download property. Instead, we need to manually
      parse the base-64 encoded string returned from the endpoint, convert it to a blob,
      and trigger the file download by creating and re-clicking an invisible a-tag.

      This implementation is definitely quite wacky and non-ideal. However, since we're
      actively searching for a replacement to the PDF service, we want to avoid adding more
      to it to support this use case. We'll have to live with the weird download implementation
      for now and replace this when we've found another option.
    */

    this.loading = true
    try {
      const quoteHash = this.quote?.hash;
      const companyId = parseInt(this.$route.params.providerId) || null;

      if (!quoteHash || !companyId) {
        console.warn('Failed to download quote because of missing parameters.')
        alert.add({
          text: '',
          color: 'red',
          title: 'Failed to download quote',
          dismissible: true,
        })
        return;
      }

      // Fetch the Base64 encoded PDF
      const response = await quotes.quoteInvoicePDFByHashAndCompanyId(quoteHash, companyId)

      if (!response?.data) {
        console.warn('Failed to download quote because of failed network call.')
        alert.add({
          text: '',
          color: 'red',
          title: 'Failed to download quote',
          dismissible: true,
        })
        return;
      }

      const blobUrl = createDownloadUri(response.data, 'application/pdf');
      triggerFileDownload(blobUrl, `charterup-quote-${this.quote.quoteId}-${companyId}.pdf`);

    } catch (error) {
      console.warn('Failed to download quote because of an error.', error)
      alert.add({
        text: '',
        color: 'red',
        title: 'Failed to download quote',
        dismissible: true,
      })
    }
    this.loading = false
  }

  openSupport(): void {
    support.open({
      reservationId: null,
      quoteId: this.quoteId,
    })
  }
}
