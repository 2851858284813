
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import CheckoutTripDetailsVehicle from '@/components/CheckoutTripDetailsVehicle.vue'
import { AmenityItemDetail } from '@/models/AmenityItem'
import { Trip, TripVehicle, AmenityType } from '@/models/dto'
import { VehicleTypeId, PaidAmenityTypeId, SplitFeatureFlag } from '@/utils/enum'
import { getCharterBusAmenities, getMiniBusAmenities } from '@/utils/amenity'
import split from '@/store/modules/split'
@Component({
  components: {
    CheckoutTripDetailsVehicle,
  },
})
export default class CheckoutTripDetailsVehicles extends Vue {
  @Prop({ type: Object, required: true }) readonly trip!: Trip

  areNewTripAmenitiesEnabled = false

  split = split

  @Watch('split.isReady', { immediate: true })
  async onSplitReady(isReady: boolean): Promise<void> {
    if (!isReady) {
      return
    }
    this.areNewTripAmenitiesEnabled = await split.isFeatureEnabled(
      SplitFeatureFlag.NewTripAmenities
    )
  }

  get vehiclesAndAmenities(): {
    vehicle: TripVehicle
    amenities: Array<AmenityItemDetail>
  }[] {
    const vehiclesAndAmenities = new Map<
      number,
      { vehicle: TripVehicle; amenities: AmenityItemDetail[] }
    >()

    for (const vehicle of this.trip.vehicles) {
      const vehicleId = vehicle.vehicleType.id
      const existing = vehiclesAndAmenities.get(vehicleId)

      if (existing) {
        existing.vehicle.quantity += vehicle.quantity
        existing.amenities = this.combineAmenities(
          existing.amenities,
          this.trip?.tripAmenities
        )
      } else {
        vehiclesAndAmenities.set(vehicleId, {
          vehicle: { ...vehicle },
          amenities: this.getPaidVehicleAmenities(
            vehicle.vehicleTypeId,
            this.trip?.tripAmenities
          ),
        })
      }
    }
    return Array.from(vehiclesAndAmenities.values())
  }

  get vehicles(): TripVehicle[] {
    const vehicles = new Map<number, TripVehicle>()
    for (const vehicle of this.trip.vehicles) {
      if (!vehicles.has(vehicle.vehicleType.id)) {
        vehicles.set(vehicle.vehicleType.id, vehicle)
      }
    }
    return Array.from(vehicles.values())
  }

  get amenities(): AmenityType[] {
    const { tripAmenities } = this.trip
    if (!tripAmenities?.length) {
      return []
    }

    const amenities = new Map<number, AmenityType>()
    for (const amenity of this.trip?.tripAmenities) {
      if (!amenities.has(amenity.id)) {
        amenities.set(amenity.id, amenity)
      }
    }

    return Array.from(amenities.values())
  }

  get paidAmenityIds(): number[] {
    return Object.keys(PaidAmenityTypeId)
      .map((x) => parseInt(x))
      .filter((x) => !isNaN(x))
  }

  get hasCharterBus(): boolean {
    return this.vehicles.some(
      (vehicle) => vehicle.vehicleTypeId === VehicleTypeId.CharterBus
    )
  }


  getPaidVehicleAmenities(vehicleTypeId, amenities): AmenityItemDetail[] {
    if (vehicleTypeId === null || !amenities.length || !amenities) {
      return []
    }
    let vehicleAmenities = amenities
    if (vehicleTypeId === VehicleTypeId.CharterBus) {
      vehicleAmenities = getCharterBusAmenities(amenities)
    } else if (vehicleTypeId === VehicleTypeId.MiniBus) {
      vehicleAmenities = getMiniBusAmenities(amenities)
    }
    return vehicleAmenities
      .filter((amenity) => this.paidAmenityIds.includes(amenity.id))
      .map((amenity) => ({
        ...amenity,
        quantity: 1,
      }))
  }

  combineAmenities(prevAmenities, newAmenities): AmenityItemDetail[] {
    const combinedAmenities = new Map()
    for (const amenity of prevAmenities) {
      combinedAmenities.set(amenity.id, amenity)
    }
    for (const amenity of newAmenities) {
      if (combinedAmenities.has(amenity.id)) {
        const existingAmenity = combinedAmenities.get(amenity.id)
        existingAmenity.quantity += 1
      } else if (this.paidAmenityIds.includes(amenity.id)) {
        combinedAmenities.set(amenity.id, {
          ...amenity,
          quantity: 1,
        })
      }
    }
    return Array.from(combinedAmenities.values())
  }
}
