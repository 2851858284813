
import {
  Vue,
  Component,
  Prop,
  InjectReactive,
  Watch,
} from 'vue-property-decorator'
import CheckoutTripDetailsVehicles from '@/components/CheckoutTripDetailsVehicles.vue'
import TripAmenityCard from '@/components/TripAmenityCard.vue'
import { AmenityType, Trip } from '@/models/dto'
import { NoteTypeId, SplitFeatureFlag, VehicleTypeKey } from '@/utils/enum'
import { getItineraryDescription } from '@/utils/string'
import { AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS } from '@/utils/amenity'
import split from '@/store/modules/split'
@Component({
  components: {
    CheckoutTripDetailsVehicles,
    TripAmenityCard
  },
})
export default class CheckoutTripDetails extends Vue {
  @Prop({ type: Object, required: true }) readonly trip!: Trip
  @Prop({ type: Boolean, required: false }) readonly expanded!: boolean
  @Prop({ type: Boolean, required: false, default: false })
  readonly hideExpand!: boolean

  @InjectReactive('isSelfServe') isSelfServe

  areNewTripAmenitiesEnabled = false
  split = split

  @Watch('split.isReady', { immediate: true })
  async onSplitReady(isReady: boolean): Promise<void> {
    if (!isReady) {
      return
    }
    this.areNewTripAmenitiesEnabled = await split.isFeatureEnabled(
      SplitFeatureFlag.NewTripAmenities
    )
  }

  get passengerCount(): number {
    return this.trip.passengerCount
  }
  get tripAmenities(): AmenityType[] {
    return this.trip.tripAmenities
  }

  get amenitySupportedVehicleTypeKeys(): VehicleTypeKey[] {
    return this.trip.vehicles
      .map( (vehicle) => vehicle.vehicleType.key)
      .filter((key) => AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS.includes(key))
  }

  get tripRequirements(): { icon: string; label: string }[] {
    const passengerCount = this.passengerCount

    const possibleRequirements = [
      {
        condition: true,
        label: `${passengerCount} passengers`,
        icon: 'user',
      },
      {
        condition: this.trip?.vehicleNeededEntireTrip,
        label: 'Vehicles to stay on-site',
        icon: 'bus_alert_warn',
      },
      {
        condition: this.trip?.ada,
        label: 'Accessible vehicle',
        icon: 'accessible',
      },
      {
        condition: this.trip?.spab,
        label: 'SPAB Certified Driver',
        icon: 'spab_2',
      },
    ]

    return possibleRequirements
      .filter(({ condition }) => condition)
      .map(({ label, icon }) => ({ label, icon }))
  }

  get hasRecurrence(): boolean {
    return this.trip?.hasRecurrence
  }

  get recurrenceDatesText(): string {
    const recurrence = this.trip?.recurrences?.find(
      (recurrence) => recurrence.active
    )
    return recurrence?.startDate && recurrence?.endDate
      ? `${this.$dayjs(recurrence.startDate).format(
          'MMM DD, YYYY'
        )} - ${this.$dayjs(recurrence.endDate).format('MMM DD, YYYY')}`
      : ''
  }

  get tripNotes(): string {
    if (!this.trip?.tripNotes || !this.trip?.tripNotes.length) {
      return ''
    }
    const customerNotes = this.trip?.tripNotes.find((note) => {
      return note.noteType === NoteTypeId.Customer
    })
    return customerNotes?.html || customerNotes?.note
  }

  get tripTitle(): string {
    return this.trip?.routeName
  }

  get tripDescription(): string {
    return getItineraryDescription(this.trip)
  }
}
