import Vue from 'vue'
import axios from 'axios'
import { baseUrl } from '@/utils/env'
import modules from '@/store/modules'
import { hasTokenValue, setRequestConfig } from '@/services/authTokenRepository'
import { StatusCodes } from 'http-status-codes'
import { load } from '@/utils/localStorage'

// Constants for excluded URLs
const urlsExcludedForBearerHeader = [
  '/login',
  '/forgot',
  '/register',
  '/reset',
  `${window.location.origin}/version.json`,
]


const configureBaseUrl = () => {
  axios.defaults.baseURL = baseUrl() || ''
}

const configureHeaders = () => {
  axios.defaults.headers.common['Accept-Language'] = load('locale') || 'en'
  axios.defaults.headers.get.Pragma = 'no-cache'
  axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store'
  axios.defaults.headers.common['Application-Origin'] = 'charterup'
}

const configureRequestInterceptor = () => {
  axios.interceptors.request.use(
    async (config) => {
      // If request is different than any of the URLS in urlsExcludedForBearerHeader
      // then send Authorization header with token from localstorage
      // TODO: I want to see if we can remove this url exlcusion list - Erik Maday 11/22/2024
      const hasToken = hasTokenValue()
      const isUrlInExcludedList = urlsExcludedForBearerHeader.includes(config.url)
      if (isUrlInExcludedList || !hasToken) {
        return config
      }

      return await setRequestConfig(config)
    },
    (error) =>
      Promise.reject(error)
  )
}

const configureResponseInterceptor = () => {
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const isUnauthorized = error?.response?.status === StatusCodes.UNAUTHORIZED
      if (isUnauthorized) {
        modules.auth.logout()
      }
      return Promise.reject(error)
    }
  )
}

const configureAxios = () => {
  configureBaseUrl()
  configureHeaders()
  configureRequestInterceptor()
  configureResponseInterceptor()
}

// Plugin setup
const Plugin = {
  install(Vue) {
    configureAxios()
    Vue.axios = axios
    Object.defineProperties(Vue.prototype, {
      axios: {
        get: () => axios,
      },
      $axios: {
        get: () => axios,
      },
    })
  },
}

Vue.use(Plugin)

export default Plugin
