import Vue from 'vue'
import { AxiosRequestConfig } from 'axios'
import jwtDecode from 'jwt-decode'
import { load, remove, save } from '@/utils/localStorage'
import { hoursToSeconds, secondsFromNow } from '@/utils/datetime'

type Token = {
  provider: string
  value: string
}

enum TokenProvider {
  Auth0 = 'auth0',
  Legacy = 'legacy',
}

/**
 * Adds a bearer token to the authorization header for outgoing requests.
 *
 * @returns {Promise<boolean>} - A promise that returns an Axios request configuration object.
 */
export const setRequestConfig = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const token = await getCurrentAuthToken()
  // If there is an existing token, add it to the request headers
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}

/**
 * Stores the token in local storage. If it's from a legacy provider, it will be stored differently.
 */
export const saveAuthToken = (token: string, legacy: boolean = false): void => {
  const newToken: Token = {
    provider: legacy ? TokenProvider.Legacy : TokenProvider.Auth0,
    value: token,
  }
  save('token', newToken)
}

/**
 * Stores the token in local storage. If it's from a legacy provider, it will be stored differently.
 */
export const removeAuthToken = (token: string, legacy: boolean = false): void => {
  remove('token')
}

/**
 * Retrieves the current authorization token from local storage.
 * Legacy tokens are given priority if they exist, otherwise the Auth0 token is used.
 * Auth0 tokens that are expiring within an hour will be refreshed.
 *
 * @returns {Promise<string | null>} - A promise that returns the current token or null if no token was found.
 */
export const getCurrentAuthToken = async (): Promise<string | null> => {
  return loadTokenValue()
}

/**
 * Checks if a token value exists.
 *
 * @returns {boolean} True if a token value exists, false otherwise.
 */
export const hasTokenValue = (): boolean => {
  return !!loadTokenValue()
}

/**
 * Loads the token value from local storage.
 *
 * This function attempts to retrieve a token from local storage. If the token is a string,
 * it returns the token directly. If the token is an object, it returns the `value` property
 * of the token object. If no token is found, it returns `null`.
 *
 * @returns {string | null} The token value if found, otherwise `null`.
 */
export const loadTokenValue = (): string | null => {
  const token = loadTokenFromLocalStorage()
  return token?.value || null
}

/**
 * Loads the authentication token from local storage.
 *
 * If the token is in the old string format, it is automatically converted
 * and saved in the new object format.
 *
 * @returns {Token | null} The token retrieved from local storage in the correct format, or null if no token is found.
 */
export const loadTokenFromLocalStorage = (): Token | null => {
  let token = load('token')

  if (!token) {
    return null
  }

  // If the token is a string, treat it as a legacy format and re-save as an object
  if (typeof token === 'string') {
    saveAuthToken(token, true)
    token = load('token')
  }

  return token as Token
}
