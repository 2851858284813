// Uncomment next line to debug in Safari
// import devtools from '@vue/devtools'
import 'babel-polyfill'

// Initialize Datadog before anything else
import { initDatadog } from './utils/datadog'

// Vue and plugins
import Vue from 'vue'
import VuetifyConfirm from 'vuetify-confirm'
import VueTheMask from 'vue-the-mask'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import router from '@/router'
import ga4 from '@/plugins/ga4'
import cuLibrary from '@/plugins/cuLibrary'
import dayjs from '@/plugins/dayjs'
import LoadScript from 'vue-plugin-load-script';

// Global styles
import '@/scss/main.scss'

// Other plugins
import '@/plugins/axios'
import '@/plugins/gmaps'

// App component and store modules
import App from '@/App.vue'
import modules from '@/store/modules'

// Scoped slots hack
import applyScopedSlotsHack from '@/plugins/scopedSlotsHack'

// before anything else, initialize datadog integration
initDatadog()

// Configurations
Vue.prototype.$dayjs = dayjs
Vue.config.productionTip = false

// Use plugins
Vue.use(LoadScript)
Vue.use(ga4)
Vue.use(VuetifyConfirm, { vuetify })
Vue.use(VueTheMask)
Vue.use(cuLibrary)

// Apply scoped slots hack
applyScopedSlotsHack(Vue)

const initApp = async (): Promise<void> => {
  // Initialize the Vue instance
  new Vue({
    router,
    i18n,
    vuetify,
    created() {
      modules.locale.setLocale(modules.locale.locale)
      if (modules.auth.isTokenSet) {
        modules.auth.autoLogin()
      }
    },
    render: (h) => h(App),
  }).$mount('#app')
}

initApp()
