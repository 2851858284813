
import {
  ReservationDetail,
  HotelRequirement,
  HotelInformation,
  AmenityType,
  RequiredVehicle,
  Note,
} from '@/models/dto'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import TripAmenityCard from '@/components/TripAmenityCard.vue'
import TripNotes from '@/components/TripNotes.vue'
import TripMetadata from '@/components/TripMetadata.vue'
import TripItinerary from '@/components/TripItinerary.vue'
import TripRequirements from '@/components/TripRequirements.vue'
import { SplitFeatureFlag, VehicleTypeKey } from '@/utils/enum'
import { AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS, getCharterBusAmenities, getMiniBusAmenities } from '@/utils/amenity'
import split from '@/store/modules/split'

const CUSTOMER_NOTE_TYPE = 2
@Component({
  components: {
    TripNotes,
    TripMetadata,
    TripAmenityCard,
    TripItinerary,
    TripRequirements,
  },
})
export default class TripInfoItineraryModal extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean
  @Prop({ type: String, required: false, default: 'Itinerary' }) readonly title: string
  @Prop({ type: Object, default: () => {}, required: false }) readonly reservation: ReservationDetail
  @Prop({ type: Object, default: () => {} }) readonly trip: any
  @Prop({ type: Array, default: () => [] }) readonly stops: any[]
  @Prop({ type: Boolean, default: false }) readonly allowReservationManagement: boolean

  areNewTripAmenitiesEnabled = false
  split = split

  @Watch('split.isReady', { immediate: true })
  async onSplitReady(isReady: boolean): Promise<void> {
    if (!isReady) {
      return
    }
    this.areNewTripAmenitiesEnabled = await split.isFeatureEnabled(
      SplitFeatureFlag.NewTripAmenities
    )
  }

  get reservationStatus(): string {
    return this.reservation?.reservationStatus
  }

  get reservationId(): number {
    return this.reservation?.reservationId
  }

  get tripStops(): any[] {
    if (this.stops && this.stops.length) {
      return this.stops
    }
    return this.reservation?.stops
  }

  get hotelRequirement(): HotelRequirement {
    return this.reservation?.hotelRequirement
  }

  get hotelInformation(): HotelInformation {
    return this.reservation?.hotelInformation
  }

  get amenities(): AmenityType[] {
    return this.reservation?.amenities || []
  }

  get isSelfServe(): boolean {
    return !!this.reservation?.isSelfServe
  }

  get amenityEligibleVehicleTypeKeys(): VehicleTypeKey[] {
    const keys = this.tripVehicles
      .map(({vehicleType}) => vehicleType.key as VehicleTypeKey)
      .filter(key => AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS.includes(key))

    return [...new Set(keys)]
  }

  get tripVehicles(): RequiredVehicle[] {
    return this.reservation?.requiredVehicles
  }

  get customerTripNotes(): Note[] {
    const notes = this.trip?.tripNotes
    if (!notes) {
      return null
    }
    return notes.filter((note) => note.noteType === CUSTOMER_NOTE_TYPE)
  }
}
