import RouteConfigWithMeta from '@/models/RouteConfig'

const preauth: RouteConfigWithMeta = {
  path: '',
  name: 'preauth',
  meta: { requiresAuth: false },
  component: () => import('@/views/Home.vue'),
  children: [
    {
      path: '/login',
      name: 'login',
      meta: { requiresAuth: false },
      component: () =>
        import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    },
    {
      path: '/signup',
      name: 'signup',
      meta: { requiresAuth: false },
      component: () =>
        import(/* webpackChunkName: "signup" */ '@/views/SignUp.vue'),
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      meta: { requiresAuth: false },
      component: () =>
        import(
          /* webpackChunkName: "forgot-password" */ '@/views/ForgotPassword.vue'
        ),
    },
    {
      path: '/reset-password/:id',
      name: 'reset-password',
      meta: { requiresAuth: false },
      component: () =>
        import(
          /* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue'
        ),
    },
    {
      path: '/selfServe/:quoteId?',
      name: 'self-serve',
      meta: { requiresAuth: false },
      component: () =>
        import(
          /* webpackChunkName: "self-serve" */ '@/views/SelfServe.vue'
        ),
    },
    {
      path: '/cancellation/:hash',
      name: 'cancellation',
      meta: { requiresAuth: false },
      component: () =>
        import(
          /* webpackChunkName: "cancellation" */ '@/views/Cancellation.vue'
        ),
    },
    {
      path: '/guest-checkout/:hash/confirmation',
      name: 'guest-checkout.confirmation',
      meta: { requiresAuth: false },
      component: () =>
        import(
          /* webpackChunkName: "checkout-confirmation" */ '@/views/GuestCheckoutConfirmation.vue'
        ),
    },
    {
      path: '/guest-checkout/:hash',
      name: 'guest-checkout',
      meta: { requiresAuth: false },
      component: () =>
        import(
          /* webpackChunkName: "guest-checkout" */ '@/views/GuestCheckout.vue'
        ),
    },
    {
      path: '/*',
      name: 'not-found',
      meta: { requiresAuth: false },
      component: () =>
        import(
          /* webpackChunkName: "not-found" */ '@/views/NotFound.vue'
        ),
    },
  ],
}

export default preauth
